.testimonialBackground {
  background: radial-gradient(circle at center bottom, #98c19d 0%, #ffffff 70%);
}

.gradientText {
  background: linear-gradient(180deg, #98c19d 0%, #c7c26b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.2s ease forwards;
}

.fade-in-1 {
  animation-delay: 0.3s;
}

.fade-in-2 {
  animation-delay: 0.5s;
}

.fade-in-3 {
  animation-delay: 0.6s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(40px);
  }
}

.fade-in-up {
  opacity: 0;
  animation: fadeInUp 1s ease forwards;
}

.fade-out-right {
  animation: fadeOutRight 0.5s ease forwards;
}

.mobile-testimonial-person{

}