
.footerContainer {
  width: 100%;
  height: 800px;
  overflow:hidden;
  background: url('../../public/static/Footer/footer_background-transformed.png') no-repeat center -85%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  visibility: hidden; // Initially hidden
  opacity: 0; // Initially invisible
  clip-path: ellipse(30% 20% at 50% 120%); // Start as a small ellipse
  transition: all 0.7s cubic-bezier(0.42, 0, 0.58, 1); // Custom bezier for slow start and fast end

  &.visible {
    visibility: visible; // Make visible
    opacity: 1; // Fade in
    clip-path: ellipse(150% 100% at 50% 50%); // Expand to full view
  }

  @media (max-width: 768px) {
    height: 550px;
  }
}

.svgContainer {
  width: 100%;
  top:  -2px;
  position:absolute;  
  justify-content: center;
  z-index: 1;

  img {
    width: 100%;
    height: auto;
    transform: scale(-1.03, -1.03);
  }
}