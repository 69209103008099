/* Base styling for the underline effect */
.hoverUnderline {
  position: relative; /* Constrain ::after to the element width */
  display: inline-block; /* Ensure it only takes the width of the text */
}

.hoverUnderline::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px; /* Position underline slightly below text */
  width: 100%;
  height: 1px;
  transform: scaleX(0); /* Start with no width */
  transform-origin: left; /* Animation starts from the left */
  transition: transform 0.3s ease; /* Smooth animation */
}

/* Hover effect to reveal the underline */
.hoverUnderline:hover::after {
  transform: scaleX(1); /* Full width on hover */
}

/* Light and dark variations for the underline */
.hoverUnderline.light-link::after {
  background-color: white; /* Underline color for light mode */
}

.hoverUnderline.dark-link::after {
  background-color: rgb(1, 1, 34); /* Underline color for dark mode */
}
