/* MobileMenu.module.scss */
@keyframes expandCircle {
    0% {
      clip-path: circle(5% at 100% 0%); /* Adjust position as needed */
    }
    100% {
      clip-path: circle(150% at 50% 50%); /* Fully expanded to cover the screen */
    }
  }

  .menu-animate {
    position: fixed;
    inset: 0;
    z-index: 20;
    background-color: transparent; /* Ensures the animation has the correct background */
    animation: expandCircle 1.5s forwards ease-in-out;
  }

  .menu-open {
    opacity: 1;
  }

  .menu-close {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }