.ScrollingContainer {
  animation: scrollAnimation 15s linear infinite;
}

.ScrollingContainerReverse {
  animation: scrollAnimationReverse 15s linear infinite;
}

@keyframes scrollAnimation {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes scrollAnimationReverse {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
