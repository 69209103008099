/* Disable scroll for iOS Safari */
.scroll-lock {
  overflow: hidden;
  position: fixed; /* Prevent viewport resizing */
  width: 100%;
  height: 100%;
}

.background-container {
  position: absolute;
  inset: 0;
  overflow: hidden;
  z-index: -1;
}

.background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.dark-overlay {
  position: absolute;
  inset: 0;
  background-color: #010122;
  z-index: 2;
}

.blob {
  position: absolute;
  background-color: transparent;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  mix-blend-mode: normal;
  animation: revealBlob 0.6s forwards ease-out;
}

.blob-1 {
  bottom: 10%;
  left: 5%;
  width: 80px;
  height: 80px;
}

.blob-2 {
  top: 15%;
  right: 5%;
  width: 100px;
  height: 100px;
}

.blob-3 {
  bottom: 15%;
  right: 10%;
  width: 80px;
  height: 80px;
}

.blob-4 {
  top: 10%;
  left: 10%;
  width: 110px;
  height: 110px;
}

.blob-5 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
}

@keyframes revealBlob {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
