/* Nav.module.scss */

.navbar {
    position: absolute;
    z-index: 1000;
    transition: opacity 300ms ease-in-out;
    will-change: opacity;
  }
  

  
  .opacity-0 {
    opacity: 0;
    pointer-events: none;
  }
  
  .opacity-100 {
    opacity: 1;
    pointer-events: auto;
  }
  