.modalContainer {
  background: rgba(0, 0, 0, 0.3); /* Slight dark overlay */
  backdrop-filter: blur(15px);
  z-index: 1000;
}

.modalContent {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Slight shadow for 3D effect */
}
