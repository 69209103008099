.smoothChangeBg {
    transition: background-color 200ms 5000ms ease-in-out;
  }
  
  .staticLogo {
    width: 400px;
    height: auto;
    opacity: 0;
    z-index: 11;
    animation: fadeIn 500ms forwards ease-in-out;
  }
  
  @media (max-width: 768px) {
    .staticLogo {
      width: 200px;
    }
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  .logoFadeOut {
    opacity: 1;
    animation: fadeOut 500ms forwards ease-in-out;
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .expandingStar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .expandingStarImg {
    width: 50px;
    height: auto;
    opacity: 0;
    transform: translate(-150px, 100px) scale(1) rotate(0deg);
    animation: expandStar 1000ms 200ms forwards ease-in-out;
  }
  
  @media (max-width: 768px) {
    .expandingStarImg {
      animation: expandStarMobile 1000ms 200ms forwards ease-in-out;
    }
  }
  
  @keyframes expandStar {
    0% {
      transform: translate(50px, 50px) scale(1) rotate(0deg);
      opacity: 1;
    }
    50% {
      transform: translate(-100px, 200px) scale(80) rotate(45deg);
      opacity: 1;
    }
    100% {
      transform: translate(-2000px, 1000px) scale(800) rotate(60deg);
      opacity: 1;
    }
  }
  
  @keyframes expandStarMobile {
    0% {
      transform: translate(0px, 0px) scale(1) rotate(0deg);
      opacity: 1;
    }
    50% {
      transform: translate(-130px, 150px) scale(80) rotate(45deg);
      opacity: 1;
    }
    100% {
      transform: translate(-1500px, 1800px) scale(800) rotate(45deg);
      opacity: 1;
    }
  }
  
  .hexagonContainer {
    position: relative;
    width: 130px;
    height: 130px;
    margin-left: 17px;
  }
  
  @media (max-width: 768px) {
    .hexagonContainer {
      width: 65px;
      height: 65px;
    }
  }
  
  .hexagonLayer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .hexagonImg {
    width: 130px;
    height: auto;
    animation: fadeInRotate 800ms 200ms ease-in-out forwards;
  }
  
  @media (max-width: 768px) {
    .hexagonImg {
      width: 65px;
    }
  }
  
  .heartImg {
    width: 84px;
    height: auto;
    opacity: 0;
    top: 70px;
    animation: fadeInGrow 800ms 300ms ease-in-out forwards;
  }
  
  @media (max-width: 768px) {
    .heartImg {
      width: 35px;
      top: 35px;
    }
  }
  
  .starImg {
    width: 117px;
    height: auto;
    top: 75px;
    left: 55px;
    opacity: 0;
    animation: fadeInRotate 800ms 900ms ease-out forwards;
  }
  
  @media (max-width: 768px) {
    .starImg {
      width: 60px;
      top: 35px;
      left: 30px;
    }
  }
  
  .logoTextLeft {
    margin-left: 24px;
  }
  
  @media (max-width: 768px) {
    .logoTextLeft {
      margin-left: 12px;
    }
  }
  
  .edenText {
    width: 235px;
    height: auto;
    opacity: 0;
    animation: fadeInLeft 1s 900ms ease-out forwards;
  }
  
  @media (max-width: 768px) {
    .edenText {
      width: 118px;
    }
  }
  
  @keyframes fadeInRotate {
    0% {
      transform: translate(-50%, -50%) scale(0) rotate(-40deg);
      opacity: 0;
    }
    100% {
      transform: translate(-50%, -50%) scale(1) rotate(0deg);
      opacity: 1;
    }
  }

  
  @keyframes fadeInGrow {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
  
  @keyframes fadeInLeft {
    0% {
      transform: translateX(-20px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }