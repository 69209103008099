.indexText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(1, 1, 34);
  font-size: 1.5rem;
  font-weight: 500;
}
/* Custom class for responsive positioning */
.alignTestimonial {
  
}

/* Larger screens (1024px and above) */
@media (max-width: 768px) {
  .alignTestimonial {
    left: 3vw;
  }
  
  
}

@media (max-width: 1024px) {
  .alignTestimonial {
    right: 300px;
  }
  
  
}
