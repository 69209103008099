.heroBackground {
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
  }
  
  .textContainer {
      padding-bottom: 150px;
    @media (max-width: 768px) {
      justify-content: flex-end; // Moves the text lower on mobile
      padding-top: 100px ; // Adjust padding to position text lower
    }
  }
  
  /* Adjust hero section height */
  .heroContainer {
    height: 1080px; // Default height for desktop, based on video height
  
    @media (max-width: 768px) {
      height: 550px; // Adjust height for mobile viewports
    }
  }
  