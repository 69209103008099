// Home.module.scss
@keyframes fadeFromTop {
    0% { 
      opacity: 0; 
      transform: translate(0, -30px); 
    }
    100% { 
      opacity: 1; 
      transform: translate(0, 0); 
    }
  }
  @keyframes fadeFromTop2 {
    0% { 
      opacity: 0; 
      transform: translate(0, -15px); 
    }
    100% { 
      opacity: 1; 
      transform: translate(0, 0); 
    }
  }  
  .fade1 {
    animation: fadeFromTop 600ms 100ms forwards;
  }
  
  .fade2 {
    animation: fadeFromTop 800ms 200ms forwards;
  }
  
  .fade3 {
    animation: fadeFromTop 1000ms 300ms forwards;
  }
  
  .fade4 {
    animation: fadeFromTop2 600ms 800ms forwards;
  }
  
  .fade5 {
    animation: fadeFromTop 700ms 800ms forwards;
  }
  
  .fade6 {
    animation: fadeFromTop 700ms 900ms forwards;
  }

  .fade7 {
    animation: fadeFromTop 700ms 1000ms forwards;
  }

  .fade8 {
    animation: fadeFromTop 600ms 1500ms forwards;
  }

  .fade9 {
    animation: fadeFromTop2 600ms 1500ms forwards;
  }

  .svgGray{
    color: rgba(255,255,255,.7);
  }
  
  .fade1, .fade2, .fade3, .fade4, .fade5, .fade6, .fade7, .fade8, .fade9 {
    opacity: 0; /* Hidden initially */
  }

  @for $i from 1 through 14 {
    .fade7_#{$i} {
      animation: fadeFromTop 600ms (1200ms + ($i - 1) * 40ms) forwards;
      opacity: 0; // Hidden initially
    }
  }
  
  
