.container {
  position: relative;
  width: 100%;
  z-index: 1;
  overflow-y: scroll;
  overflow-x: scroll;
  scrollbar-width: none; /* For Firefox */
  
  /* Hide scrollbar for Chrome, Safari and Edge */
  &::-webkit-scrollbar {
    display: none;
  }
}

.animationSVG {
  width: 100%;
  max-width: 100%;
}
